import { IAPIConfig, useApi } from "./useApi";
import { fetchGA4Data, fetchLocalPresence, fetchSearchAnalytics } from "../store/businessDashboardSlice";

import CustomToast from "../utils/cutomToast/CustomToast";
import { useDispatch } from "react-redux";

export const useBusinessDashboardApis = () => {
  const dispatch = useDispatch();
  const {doApi} = useApi();



  const FetchSearchAnalytics = async (payloadData: any) => {
  
    try {
      const values : any  =  {
        ...payloadData
      }

      const updateBusinessConfig: IAPIConfig = {
        URL: `/GSC/FetchSearchAnalytics`,
        method: "POST",
        payLoad: values,
      };
      const res : any =  await doApi(updateBusinessConfig, "seedcore");
      if (res.status === 200) {
        console.log("FetchSearchAnalytics",res.data.data);
        const finalData = [...res.data.data, {cards: res.data.cards},{trendData: res.data.trenddata}];
        dispatch(fetchSearchAnalytics(finalData));
      } else {
        CustomToast(res?.response?.data?.message, "error");
        dispatch(fetchSearchAnalytics([]));
      }
    } catch (error: any) {
      CustomToast(error?.response?.data?.message, "error");
      dispatch(fetchSearchAnalytics([]));
    }finally{
      
    }
  };


  const FetchGA4Data = async (payloadData: any) => {
  
    try {
      const values : any  =  {
        ...payloadData
      }

      const updateBusinessConfig: IAPIConfig = {
        URL: `/GA4/FetchGA4SitesData`,
        method: "POST",
        payLoad: values,
      };
      const res : any =  await doApi(updateBusinessConfig, "seedcore");
      if (res.status === 200) {
        console.log("landingpagedata",res.data);
        
        dispatch(fetchGA4Data(res.data));
      } else {
        CustomToast(res?.response?.data?.message, "error");
        dispatch(fetchGA4Data([]));
      }
    } catch (error: any) {
      CustomToast(error?.response?.data?.message, "error");
      dispatch(fetchGA4Data([]));
    }
  };

   const FetchLocalPresence = async (payloadData: any) => {
  
    try {
      const values : any  =  {
        ...payloadData
      }

      const updateBusinessConfig: IAPIConfig = {
        URL: `/Review/FetchLocalPresence?uniqueId=${values?.uniqueId}&period=${values?.period}&location=${values?.location}`,
        method: "GET",
        payLoad: values,
      };
      const res : any =  await doApi(updateBusinessConfig, "seedcore");
      if (res.status === 200) {
        console.log("landingpagedata",res.data);
        
        dispatch(fetchLocalPresence(res.data));
      } else {
        CustomToast(res?.response?.data?.message, "error");
        dispatch(fetchLocalPresence([]));
      }
    } catch (error: any) {
      CustomToast(error?.response?.data?.message, "error");
      dispatch(fetchLocalPresence([]));
    }finally{
      
    }
  };

  return { FetchSearchAnalytics, FetchGA4Data, FetchLocalPresence };
};

