import './CustomBarChartComponent.scss';

import React, { useState } from 'react';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

interface BarChartProps {
  series: { name: string; data: number[] }[]; // Your series data format
  categories: string[]; // Your category labels
  colors?: string[]; // Optional colors for the bars
}

const CustomBarChartComponent: React.FC<BarChartProps> = ({ series, categories, colors = ['#4285F4', '#B0BEC5'] }) => {
  const formatYAxisValue = (value: number) => {
    if (value === undefined) return;
    if (value >= 1000000) {
      // Format in millions
      return `${(value / 1000000).toFixed(1)}M`; // Round to one decimal place
    } else if (value >= 1000) {
      // Format in thousands
      return `${(value / 1000).toFixed(1)}k`; // Round to one decimal place
    }
    return value.toString(); // For values less than 1000, return as is
  };

  const [options] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      stacked: false, // Set to false for side-by-side bars
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '20px',
        columnWidth: '0%', // Adjust bar width for better separation
      },
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      labels: {
        style: { colors: '#8e8da4' },
      },
    },
    yaxis: {
      labels: {
        style: { colors: '#8e8da4' },
      },
    },
    grid: {
      show: true,
      borderColor: '#e0e0e0',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: { show: true },
      },
      yaxis: {
        lines: { show: false },
      },
    },
    legend: {
      show: true, // Show legend for different series
      position: 'top',
      horizontalAlign: 'center',
      offsetY: 10,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val) => `${formatYAxisValue(val)}`,
      },
      fixed: {
        enabled: true, // Ensures tooltip stays in a fixed position
        position: 'topRight', // You can try "topRight", "bottomLeft", or "bottomRight"
        offsetX: -40,
        offsetY: 100,
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={400} />
      </div>
    </div>
  );
};

export default CustomBarChartComponent;
