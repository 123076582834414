/* eslint-disable react-hooks/exhaustive-deps */

import "./TableComponent.scss";

import {
  Box,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomDialog from "../../components/Dialog/CustomDialog";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Label from "../../components/Label";
import TablePagination from "@mui/material/TablePagination";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

interface DataType {
  queries: string;
  group: string;
  clk: {
    value: string;
    increase: boolean;
    diff: string;
    percentage: string;
  };
  imp: {
    value: string;
    increase: boolean;
    diff: string;
    percentage: string;
  };
  ctr: {
    value: string;
    increase: boolean;
    diff: string;
    percentage: string;
  };
  pos: {
    value: string;
    increase: boolean;
    diff: string;
    percentage: string;
  };
}

type TableProps = {
  title: string;
  data?: DataType[];
  colSpan: number;
  message?: string;
  linkText: string;
  onLinkClick?: () => void;
  columns: string[];
};

const TableComponent: React.FC<TableProps> = ({
  title,
  data,
  colSpan,
  message = "",
  linkText,
  onLinkClick,
  columns,
}) => {
  const [pageSite, setPageSite] = React.useState(0);
  const [rowsPerPageSite, setRowsPerPageSite] = React.useState(5);

  const handleChangePageSite = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageSite(newPage);
  };

  const handleChangeRowsPerPageSite = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageSite(parseInt(event.target.value));
    setPageSite(0);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [activeLink, setActiveLink] = useState("Popular"); // Main component filter
  const [activeLinkDialog, setActiveLinkDialog] = useState("Popular"); // Dialog filter
  const [messageText, setMessageText] = useState(message);
  const [linkMessage, setLinkMessage] = useState(linkText);
  const [activeHeader, setActiveHeader] = useState<string | null>("clk");

  const convertToNumber = (value: string) => {
    if (typeof value === 'string') {
      if (value.toUpperCase().includes('K')) {
        return parseFloat(value.replace('K', '')) * 1000;
      } else if (value.toUpperCase().includes('M')) {
        return parseFloat(value.replace('M', '')) * 1000000;
      }
    }
    return parseFloat(value);
  };
  
  const filterData = (
    data: DataType[],
    activeFilter: string,
    activeHeader: string | null
  ) => {
    let filteredData = [];
  
    // Filter data based on activeFilter (activeLink)
    if (activeFilter === "Popular") {
      filteredData = data;
    } else if (activeFilter === "Growing") {
      setMessageText("");
      setLinkMessage("There are no growing to show, go to Popular.");
      filteredData = data.filter((item) => item.group === activeFilter);
    } else {
      setMessageText("");
      setLinkMessage("There are no decaying to show, go to Popular.");
      filteredData = data.filter((item) => item.group === activeFilter);
    }
  
    if (activeHeader) {
      filteredData = filteredData.sort((a: any, b: any) => {
        const valueA = convertToNumber(a[activeHeader].value);
        const valueB = convertToNumber(b[activeHeader].value);
  
        if (valueA < valueB) return 1;
        if (valueA > valueB) return -1;
        return 0;
      });
    }
  
    return filteredData;
  };  

  // UseMemo for filtered data
  const filteredData = useMemo(
    () => filterData(data || [], activeLink, activeHeader), // Pass activeHeader here
    [data, activeLink, activeHeader] // Depend on activeLink and activeHeader
  );

  // UseMemo for filteredDialogData
  const filteredDialogData = useMemo(
    () => filterData(data || [], activeLinkDialog, activeHeader), // Pass activeHeader here
    [data, activeLinkDialog, activeHeader] // Depend on activeLinkDialog and activeHeader
  );

  const handleLinkClick = (link: string, context: "main" | "dialog") => {
    if (context === "main") {
      setActiveLink(link);
    } else if (context === "dialog") {
      setActiveLinkDialog(link);
    }
  };

  const handleHeaderClick = (header: string) => {
    setActiveHeader(header);
  };

  return (
    <Box className="Custom-table-wrapper">
      <TableContainer
        component={Paper}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Space between content and button
          padding: "16px",
          minHeight: "300px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <Typography variant="subtitle1">{title}</Typography>
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={() => handleLinkClick("Popular", "main")}
              sx={{
                bgcolor: activeLink === "Popular" ? "grey.300" : "transparent",
                borderRadius: "50%",
                transition: "background-color 0.3s",
              }}
            >
              <EmojiEventsIcon />
            </IconButton>
            <IconButton
              onClick={() => handleLinkClick("Growing", "main")}
              sx={{
                bgcolor: activeLink === "Growing" ? "grey.300" : "transparent",
                borderRadius: "50%",
                transition: "background-color 0.3s",
              }}
            >
              <TrendingUpIcon />
            </IconButton>
            <IconButton
              onClick={() => handleLinkClick("Decaying", "main")}
              sx={{
                bgcolor: activeLink === "Decaying" ? "grey.300" : "transparent",
                borderRadius: "50%",
                transition: "background-color 0.3s",
              }}
            >
              <TrendingDownIcon />
            </IconButton>
          </Stack>
        </Box>

        <Table sx={{ overflow: "hidden" }}>
          <TableHead sx={{ borderBottom: "1px solid #e0e0e0" }}>
            <TableRow>
              <TableCell sx={{ minWidth: "130px !important" }}></TableCell>

              {columns.includes("CLK") && (
                <TableCell
                  className="tableCellHeader"
                  onClick={() => handleHeaderClick("clk")}
                  sx={{
                    cursor: "pointer",
                    borderBottom:
                      activeHeader === "clk"
                        ? "4px solid #007bff !important"
                        : "4px solid transparent !important",
                    transition: "border-color 0.3s ease !important",
                  }}
                >
                  CLK
                </TableCell>
              )}

              {columns.includes("IMP") && (
                <TableCell
                  className="tableCellHeader"
                  onClick={() => handleHeaderClick("imp")}
                  sx={{
                    cursor: "pointer",
                    borderBottom:
                      activeHeader === "imp"
                        ? "4px solid #6f42c1 !important"
                        : "4px solid transparent !important",
                    transition: "border-color 0.3s ease !important",
                  }}
                >
                  IMP
                </TableCell>
              )}

              {columns.includes("CTR") && (
                <TableCell
                  className="tableCellHeader"
                  onClick={() => handleHeaderClick("ctr")}
                  sx={{
                    cursor: "pointer",
                    borderBottom:
                      activeHeader === "ctr"
                        ? "4px solid #28a745 !important"
                        : "4px solid transparent !important",
                    transition: "border-color 0.3s ease !important",
                  }}
                >
                  CTR
                </TableCell>
              )}

              {columns.includes("POS") && (
                <TableCell
                  className="tableCellHeader"
                  onClick={() => handleHeaderClick("pos")}
                  sx={{
                    cursor: "pointer",
                    borderBottom:
                      activeHeader === "pos"
                        ? "4px solid #fd7e14 !important"
                        : "4px solid transparent !important",
                    transition: "border-color 0.3s ease !important",
                  }}
                >
                  POS
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ minHeight: "600px" }}>
            {filteredData && filteredData.length > 0 ? (
              filteredData.slice(0, 10).map((row, index) => (
                <TableRow key={index} sx={{ height: "60px" }}>
                  <TableCell className="ellipsisText" sx={{ padding: "8px 0" }}>
                    {row.queries}
                  </TableCell>
                  {columns.includes("CLK") && (
                    <TableCell sx={{ maxWidth: "65px", textAlign: "center" }}>
                      <div>{row.clk.value}</div>
                      {row.clk.percentage !== undefined && (
                        <Label
                          increase={row.clk.increase}
                          label={`${row.clk.percentage}`}
                          color={row.clk.increase ? "success" : "error"}
                          size="12px"
                          iconsize="12px"
                        />
                      )}
                    </TableCell>
                  )}
                  {columns.includes("IMP") && (
                    <TableCell sx={{ maxWidth: "65px", textAlign: "center" }}>
                      <div>{row.imp.value}</div>
                      {row.imp.percentage !== undefined && (
                        <Label
                          increase={row.imp.increase}
                          label={`${row.imp.percentage}`}
                          color={row.imp.increase ? "success" : "error"}
                          size="12px"
                          iconsize="12px"
                        />
                      )}
                    </TableCell>
                  )}
                  {columns.includes("CTR") && (
                    <TableCell sx={{ maxWidth: "65px", textAlign: "center" }}>
                      <div>{row.ctr.value}</div>
                      {row.ctr.percentage !== undefined && (
                        <Label
                          increase={row.ctr.increase}
                          label={`${row.ctr.percentage}`}
                          color={row.ctr.increase ? "success" : "error"}
                          size="12px"
                          iconsize="12px"
                        />
                      )}
                    </TableCell>
                  )}
                  {columns.includes("POS") && (
                    <TableCell sx={{ maxWidth: "65px", textAlign: "center" }}>
                      <div>{row.pos.value}</div>
                      {row.pos.percentage !== undefined && (
                        <Label
                          increase={row.pos.increase}
                          label={`${row.pos.percentage}`}
                          color={row.pos.increase ? "success" : "error"}
                          size="12px"
                          iconsize="12px"
                        />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "0.8rem",
                    }}
                  >
                    {messageText}
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    <span>
                      {messageText && (
                        <Link
                          component="button"
                          variant="body1"
                          onClick={onLinkClick}
                          sx={{ color: "blue", textDecoration: "none" }}
                        >
                          Define
                        </Link>
                      )}{" "}
                      {linkMessage}
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "auto",
          }}
        >
          {filteredData && filteredData.length > 0 && (
            <IconButton onClick={() => setOpen(true)}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </Box>
      </TableContainer>

      <CustomDialog
        open={open}
        onClose={handleClose}
        width={"100%"}
        backgroundDropColor={true}
      >
        <Box className="Custom-table-wrapper">
          <TableContainer
            sx={{ marginTop: "1.2rem", width: "1056px", maxWidth: "1056px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 1,
                marginBottom: 1,
              }}
            >
              <Typography variant="subtitle1">{title}</Typography>
              <Stack direction="row" spacing={1}>
                <IconButton
                  onClick={() => handleLinkClick("Popular", "dialog")}
                  sx={{
                    bgcolor:
                      activeLinkDialog === "Popular"
                        ? "grey.300"
                        : "transparent",
                    borderRadius: "50%",
                    transition: "background-color 0.3s",
                  }}
                >
                  <EmojiEventsIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleLinkClick("Growing", "dialog")}
                  sx={{
                    bgcolor:
                      activeLinkDialog === "Growing"
                        ? "grey.300"
                        : "transparent",
                    borderRadius: "50%",
                    transition: "background-color 0.3s",
                  }}
                >
                  <TrendingUpIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleLinkClick("Decaying", "dialog")}
                  sx={{
                    bgcolor:
                      activeLinkDialog === "Decaying"
                        ? "grey.300"
                        : "transparent",
                    borderRadius: "50%",
                    transition: "background-color 0.3s",
                  }}
                >
                  <TrendingDownIcon />
                </IconButton>
              </Stack>
            </Box>
            <Table sx={{ overflow: "hidden" }}>
              <TableHead sx={{ borderBottom: "1px solid #e0e0e0" }}>
                <TableRow>
                  <TableCell sx={{ minWidth: "704px !important" }}></TableCell>

                  {columns.includes("CLK") && (
                    <TableCell
                      className="tableCellHeader"
                      onClick={() => handleHeaderClick("clk")}
                      sx={{
                        cursor: "pointer",
                        borderBottom:
                          activeHeader === "clk"
                            ? "4px solid #007bff !important"
                            : "4px solid transparent !important",
                        transition: "border-color 0.3s ease !important", // Added !important to ensure it's applied
                      }}
                    >
                      CLK
                    </TableCell>
                  )}

                  {columns.includes("IMP") && (
                    <TableCell
                      className="tableCellHeader"
                      onClick={() => handleHeaderClick("imp")}
                      sx={{
                        cursor: "pointer",
                        borderBottom:
                          activeHeader === "imp"
                            ? "4px solid #6f42c1 !important"
                            : "4px solid transparent !important",
                        transition: "border-color 0.3s ease !important",
                      }}
                    >
                      IMP
                    </TableCell>
                  )}

                  {columns.includes("CTR") && (
                    <TableCell
                      className="tableCellHeader"
                      onClick={() => handleHeaderClick("ctr")}
                      sx={{
                        cursor: "pointer",
                        borderBottom:
                          activeHeader === "ctr"
                            ? "4px solid #28a745 !important"
                            : "4px solid transparent !important",
                        transition: "border-color 0.3s ease !important",
                      }}
                    >
                      CTR
                    </TableCell>
                  )}

                  {columns.includes("POS") && (
                    <TableCell
                      className="tableCellHeader"
                      onClick={() => handleHeaderClick("pos")}
                      sx={{
                        cursor: "pointer",
                        borderBottom:
                          activeHeader === "pos"
                            ? "4px solid #fd7e14 !important"
                            : "4px solid transparent !important",
                        transition: "border-color 0.3s ease !important",
                      }}
                    >
                      POS
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredDialogData && filteredDialogData.length > 0 ? (
                  filteredDialogData
                    ?.slice(
                      pageSite * rowsPerPageSite,
                      pageSite * rowsPerPageSite + rowsPerPageSite
                    )
                    .map((row, index) => (
                      <TableRow key={index} sx={{ height: "60px" }}>
                        <TableCell
                          className="ellipsisText"
                          sx={{ padding: "8px 0" }}
                        >
                          {row.queries}
                        </TableCell>
                        {columns.includes("CLK") && (
                          <TableCell
                            sx={{ maxWidth: "65px", textAlign: "center" }}
                          >
                            <div>{row.clk.value}</div>
                            {row.clk.percentage !== undefined && (
                              <Label
                                increase={row.clk.increase}
                                label={`${row.clk.percentage}`}
                                color={row.clk.increase ? "success" : "error"}
                                size="12px"
                                iconsize="12px"
                              />
                            )}
                          </TableCell>
                        )}
                        {columns.includes("IMP") && (
                          <TableCell
                            sx={{ maxWidth: "65px", textAlign: "center" }}
                          >
                            <div>{row.imp.value}</div>
                            {row.imp.percentage !== undefined && (
                              <Label
                                increase={row.imp.increase}
                                label={`${row.imp.percentage}`}
                                color={row.imp.increase ? "success" : "error"}
                                size="12px"
                                iconsize="12px"
                              />
                            )}
                          </TableCell>
                        )}
                        {columns.includes("CTR") && (
                          <TableCell
                            sx={{ maxWidth: "65px", textAlign: "center" }}
                          >
                            <div>{row.ctr.value}</div>
                            {row.ctr.percentage !== undefined && (
                              <Label
                                increase={row.ctr.increase}
                                label={`${row.ctr.percentage}`}
                                color={row.ctr.increase ? "success" : "error"}
                                size="12px"
                                iconsize="12px"
                              />
                            )}
                          </TableCell>
                        )}
                        {columns.includes("POS") && (
                          <TableCell
                            sx={{ maxWidth: "65px", textAlign: "center" }}
                          >
                            <div>{row.pos.value}</div>
                            {row.pos.percentage !== undefined && (
                              <Label
                                increase={row.pos.increase}
                                label={`${row.pos.percentage}`}
                                color={row.pos.increase ? "success" : "error"}
                                size="12px"
                                iconsize="12px"
                              />
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={colSpan}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          marginBottom: "0.8rem",
                        }}
                      >
                        {messageText}
                      </Typography>
                      <Typography variant="body1" sx={{ textAlign: "center" }}>
                        <span>
                          {messageText && (
                            <Link
                              component="button"
                              variant="body1"
                              onClick={onLinkClick}
                              sx={{ color: "blue", textDecoration: "none" }}
                            >
                              Define
                            </Link>
                          )}{" "}
                          {linkMessage}
                        </span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={filteredData ? filteredData?.length : 0}
                  rowsPerPage={rowsPerPageSite}
                  page={pageSite}
                  onPageChange={handleChangePageSite}
                  onRowsPerPageChange={handleChangeRowsPerPageSite}
                  sx={{
                    color: "black", // Text color
                    "& .MuiTablePagination-selectIcon": {
                      color: "black", // Dropdown arrow near rows per page
                    },
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "rgba(255, 255, 255, 0.1)", // Dropdown background
                          backdropFilter: "blur(18px)", // Blurred background for the dropdown
                          "& .MuiMenuItem-root": {
                            color: "black", // Text color for options
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker background on hover
                          },
                          "& .Mui-selected": {
                            backgroundColor:
                              "rgba(255, 255, 255, 0.3) !important", // Background color for selected option
                          },
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </TableContainer>
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default TableComponent;
