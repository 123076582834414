import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

interface Document {
  type: string;
  filename: string;
  url: string;
}

interface BusinessSectionProps {
  key: number,
  businessName: string;
  documents: Document[];
}

const BusinessSection: React.FC<BusinessSectionProps> = ({ businessName, documents }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -140 : 140;
      scrollRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const getDocumentIcon = (type: string) => {
    switch (type) {
      case 'pdf':
        return 'https://techterms.com/img/lg/pdf_109.png'; // Placeholder PDF icon
      case 'excel':
        return 'https://www.pk-anexcelexpert.com/wp-content/uploads/2018/10/Excel-Icon.png'; // Excel icon
      case 'csv':
        return 'https://www.pk-anexcelexpert.com/wp-content/uploads/2018/10/Excel-Icon.png'; // Excel icon
      case 'image':
        return 'https://via.placeholder.com/100'; // Placeholder for images
      default:
        return '';
    }
  };

  const [open, setOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<Document | null>(null);

  const handleClickOpen = (doc: Document) => {
    console.log("doc", doc.url);
    
    setSelectedDoc(doc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDoc(null);
  };

  return (
    <div className="business-section">
      <div className="business-header">
        <h3>{businessName}</h3>
        <div className="document-search">
          <Button variant="outlined" startIcon={<SearchIcon />}>
            Document Search
          </Button>
        </div>
      </div>

      {/* Left Scroll Button */}
      <button className="scroll-button left" onClick={() => handleScroll('left')}>
        <ArrowBackIosNewIcon className="arrow" />
      </button>

      <div className="document-list" ref={scrollRef}>
        {documents.map((doc, index) => (
          <div key={index} className="document-item">
            <img src={getDocumentIcon(doc.type)} alt={doc.filename} />
            <p>{doc.filename.length > 13 ? `${doc.filename.slice(0, 13)}..` : doc.filename}</p>
            <div className="hover-overlay">
              <span onClick={() => handleClickOpen(doc)}>View</span>
            </div>
          </div>
        ))}
      </div>

      {/* Right Scroll Button */}
      <button className="scroll-button right" onClick={() => handleScroll('right')}>
        <ArrowForwardIosIcon className="arrow" />
      </button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth >
        <DialogTitle>
          File Preview
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Add your file preview logic here */}
          {(selectedDoc?.type === 'pdf' || selectedDoc?.type === 'excel' || selectedDoc?.type === 'csv') && selectedDoc?.url && (
          
            <iframe
              src={`https://docs.google.com/viewer?url=${selectedDoc.url}&embedded=true`}
              width="100%"
              height="500px"
              title={selectedDoc.filename}
              style={{ border: 'none' }}
            />
          
          )}
          
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BusinessSection;
