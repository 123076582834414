const firebaseConfig = {
      apiKey: "AIzaSyB7RP4RcPP57uHD0s3fvujFIGjG0lWPzRQ",
      authDomain: "seedmain-e569c.firebaseapp.com",
      projectId: "seedmain-e569c",
      storageBucket: "seedmain-e569c.appspot.com",
      messagingSenderId: "169795176897",
      appId: "1:169795176897:web:6a78b904c7e574d6cd8740",
      measurementId: "G-SJRV2GW3D0"
  };
  
  export default firebaseConfig;

  
  