import './LoginPagePresentation.scss';

import * as yup from "yup";

import { Button, InputAdornment } from '@mui/material';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import React,{ useContext, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import  { useApi, IAPIConfig } from '../../custom_hooks/useApi';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CustomToast from '../../utils/cutomToast/CustomToast';
import { GlobalContext } from '../../utils/globalContext/GlobalContext';
import Loader from '../../utils/loader/Loader';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { login } from "../../store/authSlice";
import { urlConstants } from '../../components/constants/globalConstants';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {urlConstants.CopyRights}
    </Typography>
  );
}

interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const LoginPagePresentation = () => {
  const { doApi } = useApi();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { excludeFromTimeout, includeInTimeout } = useContext(GlobalContext);

  useEffect(() => {
    excludeFromTimeout('LoginPagePresentation');

    return () => {
      includeInTimeout('LoginPagePresentation');
    };
  }, [excludeFromTimeout, includeInTimeout]);

  const handleFormSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    if(values.email.trim() === ""){
      CustomToast('Please enter email', 'error')
      return
    }
    if(values.password.trim() === ""){
      CustomToast('Please enter password', 'error')
      return
    }
    const loginPayLoad = {
      email: values.email,
      password: values.password,
    };
    const loginConfig: IAPIConfig = {
      URL: "/login",
      method: "POST",
      payLoad: loginPayLoad,
    };
    setLoading(true);
    doApi(loginConfig, 'seedmain')
      .then((response: any) => {
        const res = response.data;
        if (res.success) {
          CustomToast(`Login Successful`, "success");
          const userResponse = res.data;
          const authData = {
            token: userResponse.token,
            userDetails: userResponse
          };
          dispatch(login(authData));
          navigate("/");
        
          if (values.rememberMe === true) {
            localStorage.setItem("rememberedEmail", values.email);
            localStorage.setItem("rememberedPassword", values.password);
            localStorage.setItem("remembered", "1");
          } else {
            localStorage.setItem("rememberedEmail", "");
            localStorage.setItem("rememberedPassword", "");
            localStorage.setItem("remembered", "0");
          }
         
        } else {
          CustomToast(res.message, "error");
          sessionStorage.setItem("auth", "0");
        }
      })
      .catch((err: any) => {
        CustomToast(err, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const checkoutSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .min(5, "Email is too short - it should min length 5 chars."),
    password: yup
      .string()
      .required("Password is required.")
      .min(5, "Password is too short - should be 5 chars minimum."),
    rememberMe: yup.boolean(),
  });

  const initialValues: FormValues = {
    email: localStorage.getItem("rememberedEmail") || "",
    password: localStorage.getItem("rememberedPassword") || "",
    rememberMe: localStorage.getItem("remembered") === "1" ? true : false,
  };

  return (
      <Loader spinning={loading}>
      <Container component="main"  maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }: FormikProps<FormValues>) => (
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <div className="login-container">
                  <div style={{ width: "100%" }}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      type="text"
                      label="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      id="txtUserName"
                      name="email"
                      size="small"
                      autoComplete="off"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      id="txtPassword"
                      size="small"
                      autoComplete="off"
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box
                              component="div"
                              onClick={handleTogglePassword}
                              onMouseDown={(event) => event.preventDefault()}
                              style={{
                                backgroundColor: "none",
                                marginTop: 5,
                                cursor: "pointer",
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  >
                    <Field name="rememberMe" type="checkbox" as={Checkbox}  id="chkRemember"/>
                    <label>Remember Me</label>
                  </div>
                  <Button variant='contained' color='primary' size='large' type="submit" id="btnLogin">
                    {loading ? <>Loading..</> : <>Login</>}
                  </Button>
                </div>
              </Box>
            )}
          </Formik>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      </Loader>
  );
}

export default LoginPagePresentation;
