import './StatsCards.scss';

import { Box, Card, CardContent, Rating, Typography } from '@mui/material';

import Label from '../../components/Label';
import React from 'react';
import StarIcon from '@mui/icons-material/Star';

interface StatsCardProps {
  title: string;
  value: string;
  percentage: string;
  increase: boolean;
  description?: string;
}

const StatsCardWithStars: React.FC<StatsCardProps> = ({ title, value, percentage, increase, description }) => {

  
  return (
    <Card className="stats-card">
      <CardContent>
      <Typography variant="h5">{title}</Typography>

        <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
      }}> 
        <Typography variant="h3">{value}</Typography>

          <Rating
            name="star-rating"
            value={parseFloat(value)}
            readOnly // Makes it display-only
            precision={0.1} // Allows half-star rating
            icon={<StarIcon fontSize="inherit" sx={{ color: '#FF9800' }} />}
            emptyIcon={<StarIcon fontSize="inherit" sx={{ color: '#ccc' }} />} // Gray for empty stars
        />
        </Box>
        <Label
            increase={increase}
            label={percentage}
            color={increase ? 'success' : 'error'}
          /> {description}
      </CardContent>
    </Card>
  );
};

export default StatsCardWithStars;
