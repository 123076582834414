import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchConsoleData: [],
  searchConsoleDate: [],
  searchConsolePage: [],
  searchConsoleQuery: [],
  searchConsoleCards:{},
  searchConsoleTrendData:{},
  searchConsoleContentGroups:{},
  searchConsoleQueryClusters:{},
  landingPageData: [],
  localPresence: [],
  loading: false,
  error: null,
//   profileDataByUniqueID: {}
};

const businessSlice = createSlice({
  name: "businessProfiles",
  initialState,
  reducers: {

    fetchSearchAnalytics(state, action) {
      console.log("fetchSearchAnalytics", action.payload);
      
      state.searchConsoleData = action.payload;
      state.searchConsoleDate = action.payload.map((item) => item.date);
      state.searchConsolePage = action.payload.map((item) => item.page);
      state.searchConsoleQuery = action.payload.map((item) => item.query);
      state.searchConsoleCards = action.payload.map((item) => item.cards);
      state.searchConsoleTrendData = action.payload.map((item) => item.trendData);
      state.searchConsoleQueryClusters = action.payload.map((item) => item.queryclusters);
      state.searchConsoleContentGroups = action.payload.map((item) => item.contentgroups);
      state.loading = false;
    },

    fetchGA4Data(state, action) {
        console.log("fetchGA4Data", action.payload);
      state.landingPageData = action.payload;
      state.loading = false;
    },
    fetchLocalPresence(state, action) {
      console.log("fetchLocalPresence", action.payload);
    state.localPresence = action.payload;
    state.loading = false;
  },
  },
});


export const { fetchSearchAnalytics, fetchGA4Data, fetchLocalPresence  } = businessSlice.actions;
export const businessDashboardReducer = businessSlice.reducer;
