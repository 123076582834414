import { AxiosError, AxiosResponse } from "axios";
import { axiosAPI } from "../api/axios";
import { useState } from "react";

export interface IAPIConfig {
  URL: string;
  method: string;
  params?: string;
  payLoad?: Record<string, unknown>;
  msg?: string | undefined;
  accessCode?: string;
}

interface IMethods {
  URL: string;
  payLoad?: Record<string, unknown>;
  msg?: string | undefined;
  accessCode?: string;
}

export const useApi = () => {
  const [apiData, setApiData] = useState<AxiosResponse | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);

  const handleSuccessCallback = (res: AxiosResponse) => {
    setApiData(res);
  };

  const errorCallback = (res: AxiosError) => {
    setError(res);
  };

  const URLsExemptOfAuth: Array<string> = ["/login"];

  const getAuthHeaders = (URL: string, env: string) => {
    if (!URLsExemptOfAuth.includes(URL)) {
      const token = sessionStorage.getItem("token")?.replaceAll('"', "");
      if (token) {
        if (env === "seedcore") {
          if(URL.includes("UploadToBlob")){
            return {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data", 
              "Cache-Control": "no-cache",
            };
          }else{
            return {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
            };
          }
        
        }else {
          return {
            token,
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          };
        }
      }
    }
    return {};
  };

  const fetchMethod = ({ URL }: { URL: string }, env: string) => {
 

    const instance = axiosAPI(env);
    const headers = getAuthHeaders(URL, env);

    return instance
      .get(URL, { headers })
      .then((response: AxiosResponse) => {
        handleSuccessCallback(response);
        return response;
      })
      .catch((err: AxiosError) => {
        errorCallback(err);
        return err;
      });
  };

  const postMethod = ({ URL, payLoad }: IMethods, env: string) => {
    const instance = axiosAPI(env);
    const headers = getAuthHeaders(URL, env);

    return instance
      .post(URL, payLoad, { headers })
      .then((response: AxiosResponse) => {
        handleSuccessCallback(response);
        return response;
      })
      .catch((err: AxiosError) => {
        errorCallback(err);
        return err;
      });
  };

  const putMethod = ({ URL, payLoad }: IMethods, env: string) => {
    const instance = axiosAPI(env);
    const headers = getAuthHeaders(URL, env);

    return instance
      .put(URL, payLoad, { headers })
      .then((response: AxiosResponse) => {
        handleSuccessCallback(response);
        return response;
      })
      .catch((err: AxiosError) => {
        errorCallback(err);
        return err;
      });
  };

  const doApi = (obj: IAPIConfig, env: string) => {

    switch (obj?.method) {
      case "GET":
        return fetchMethod(obj, env);
      case "POST":
        return postMethod(obj, env);
      case "PUT":
        return putMethod(obj, env);
      default:
        return new Promise((resolve, reject) => {
          resolve("Method not found");
        });
    };
  };

  return { apiData, doApi, error };
};


