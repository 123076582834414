import "./StatsCards.scss";

import { Card, CardContent, Link, Typography } from "@mui/material";

import Label from "../../components/Label";
import React from "react";

interface StatsCardProps {
  title: string;
  value: string;
  percentage: string;
  increase: boolean;
  description?: string;
  message?: string;
  handleDefineClick?: () => void;
  con?:boolean
}

const StatsCard: React.FC<StatsCardProps> = ({
  title,
  value,
  percentage,
  increase,
  description,
  message = "",
  handleDefineClick,
  con
}) => {
  const isDefined = con || (title && parseInt(value) !== 0);
  return (
    <Card className="stats-card">
      {isDefined ? (
        <CardContent>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="h3">{value}</Typography>
          {percentage !== "0%" && <Label
            increase={increase}
            label={percentage}
            color={increase ? "success" : "error"}
          />}
          {description}
        </CardContent>
      ) : (
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <Typography variant="body1" color="textSecondary" align="center">
            {message && (
              <Link
                component="button"
                onClick={handleDefineClick}
                sx={{ color: "blue", textDecoration: "none" }}
              >
                Define
              </Link>
            )}{" "}
            {message}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default StatsCard;
