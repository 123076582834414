import "./ViewUsers.scss";

import { Button, ConfigProvider, Modal, Space, Table, Tooltip } from "antd";
import {
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import  {useApi, IAPIConfig } from "../../../custom_hooks/useApi";

import CreateUser from "../create/CreateUser";
import CustomToast from "../../../utils/cutomToast/CustomToast";
import Loader from "../../../utils/loader/Loader";
import { urlConstants } from "../../../components/constants/globalConstants";
import { useDispatch } from "react-redux";
import { userEntityTypeAction } from "../../../store/userEntityType";
import { userProfile } from "../../../store/userProfileSlice";

//import { SettingsOutlined } from "@mui/icons-material";

//import useSendPushNotifications from "../../../custom_hooks/useSendPushNotifications";




type UserDetails = {
  UserName: string;
  Email: string;
  FirstName: string;
  LastName: string;
  MobileNo: string;
  RoleId: string;
  RoleName: string;
  Password: string;
  ConfirmPassword: string;
  AssociatedBusiness: { name: string; uniqueId: string }[];
  Id: Number;
  UniqueId: string,
  Entity: string
};

const ViewUsers: React.FC = () => {
  const [users, setUsers] = useState<UserDetails[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editable, setEditable] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstTime = useRef(true);
  const dispatch = useDispatch();
  //const { sendPushNotifications } = useSendPushNotifications();
  const showEditModal = (
    e: React.MouseEvent<HTMLButtonElement>,
    user: UserDetails,
    edit: boolean,
    update: boolean
  ) => {
    e.preventDefault();
    console.log("User: ", user);
    console.log("Edit: ", edit, "Update: ", update);
    dispatch(userProfile(user));
    setIsModalVisible(true);
    setEditable(edit);
    setUpdate(update);
    console.log("Modal visibility: ", isModalVisible);
  };

  const { doApi } = useApi();

  const handleCancel = () => {
    setIsModalVisible(false);
    if (update) {
      firstTime.current = true;
    }
    dispatch(userProfile({}));
  };

  const getUserProfiles = async () => {
    setLoading(true);
    try {
      const fetchUserProfilesConfig: IAPIConfig = {
        URL: `/get-user-profiles`,
        method: "GET"
      };
      const res: any = await doApi(fetchUserProfilesConfig, "seedmain");
      if (res.data.success) {
          setUsers(res.data.data);
      } else {
        CustomToast(res.data.message, "error");
      }
    } catch (error: any) {
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    } 
  };

  useEffect(() => {
    if (firstTime.current) {
       getUserProfiles();
      firstTime.current = false;
    }
  });

  const handleOAuth2Redirect = (record: UserDetails) => {
    const userEntityType = { UniqueId: record.UniqueId, Entity: record.Entity };
    dispatch(userEntityTypeAction({userEntityType:userEntityType}));
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/analytics.readonly
    &access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${urlConstants.ENV_URL}/signin-google&flowName=GeneralOAuthFlow&client_id=137948605480-56kn6b8a8ti77d95qu3usknlanq05fsj.apps.googleusercontent.com`;
  };

  // const handleDelete = async (key: Number, name: string) => {
  //   try {
  //     const removeUserConfig: IAPIConfig = {
  //       URL: `/remove-user-profile?id=${key}`,
  //       method: "GET",
  //     };
  //     const res: any = await doApi(removeUserConfig, "seedmain");
  //     if (res.data.success === true) {
  //       firstTime.current = true;
  //       sendPushNotifications({
  //         title: name,
  //         body: `User Profile deleted successfully`
  //      });
  //     } else {
  //       CustomToast(res.data.message, "error");
  //     }
  //   } catch (error: any) {
  //     CustomToast(error.message, "error");
  //   }
  // };


  const columns = [
    { title: "Name", dataIndex: "UserName", key: "UserName" },
    { title: "Email", dataIndex: "Email", key: "Email" },
    { title: "Entity", dataIndex: "Entity", key: "Entity" },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: UserDetails) => (
        <Space size="middle" className="action-buttons">
          <Tooltip title="View User">
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                showEditModal(e, record, true, false)
              }
            />
          </Tooltip>
          <Tooltip title="Login">
            <Button
              type="primary"
              icon={<UserOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>{
                 e.preventDefault();
                 handleOAuth2Redirect(record)
                 //const data = { uniqueId: record.uniqueId, name: record.name };
                 //navigate("/business/business-login", {state: data});
                 //navigate("/business/super-admin-login", {state: data} )
                }
              }
            />
          </Tooltip>
          {/* <Tooltip title="Edit User">
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                showEditModal(e, record, false, true)
              }
            />
          </Tooltip>
          <Tooltip title="Delete Profile">
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record.Id, record.UserName)}
            />
          </Tooltip>
          <Tooltip title="Settings">
            <Button
              type="primary"
              icon={<SettingsOutlined />}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                console.log("Settings")
              }
            />
          </Tooltip> */}
        </Space>
      ),
    },
  ];

  const theme = {
    token: {
      colorPrimary: "#003366",
    },
  };

  return (
    <Loader spinning={loading}>
      <div className="view-page-user">
        <h2>User Profiles</h2>
        <div className="ant-table-wrapper">
          <Table
            columns={columns}
            dataSource={users.map((user) => ({
              ...user,
              key: user.Id,
            }))}
            pagination={{ pageSize: 10 }}
          />
        </div>
        <ConfigProvider theme={theme}>
          <div className="edit-user">
            <Modal
              title={update ? "Edit User" : "View User"}
              open={isModalVisible}
              onCancel={handleCancel}
              footer={null}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CreateUser update={update} editable={editable} />
              </div>
            </Modal>
          </div>
        </ConfigProvider>
      </div>
    </Loader>
  );
};

export default ViewUsers;
