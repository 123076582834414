export const urlConstants = Object.freeze({
    NODE_ENV_TEST_URL: 'https://seedmain.seedinternaldev.xyz/api/1',
    NODE_ENV_PROD_URL: '',
    NODE_ENV_Localhost_URL: 'http://localhost:4922/api/1',
    CORE_ENV_TEST_URL: 'https://seedcore.seedinternaldev.xyz/api',
    CORE_ENV_PROD_URL: '',
    CopyRights: `Copyrights @ Kanahiku LLC ${new Date().getFullYear()}.`,
    TEST_AI_CHATBOT_URL: 'https://reviewai.seedinternaldev.xyz',
    SessionTimeout: 30 * 60 * 1000,
    ENV_URL: 'https://www.seedinternaldev.xyz', //http://localhost:3000 or https://www.seedinternaldev.xyz
    AI_API_KEY: 'sk-proj-J3DiIDImJmJ6LuCnkcxCT3BlbkFJOe6mOPcgxsZD0W5gKvac',
    MASSIC_VERSION: "massic v1.1.0",
    SENDER_EMAIL_ID: "rajkumar@kanahiku.com",
})
